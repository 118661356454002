import { template as template_8f7c35af407f4b89aa001931e1cdfe74 } from "@ember/template-compiler";
import SearchMenuPanel from "../search-menu-panel";
const SearchMenuWrapper = template_8f7c35af407f4b89aa001931e1cdfe74(`
  <div class="search-menu glimmer-search-menu" aria-live="polite" ...attributes>
    <SearchMenuPanel @closeSearchMenu={{@closeSearchMenu}} />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SearchMenuWrapper;
