import { template as template_312a73729eaa4c69b7b52d1a4c73a363 } from "@ember/template-compiler";
const FKText = template_312a73729eaa4c69b7b52d1a4c73a363(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
