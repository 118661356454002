import { template as template_f211eaa3b1b142cab8bf554f623995b8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_f211eaa3b1b142cab8bf554f623995b8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
