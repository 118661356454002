import { template as template_ad07e0d1bd3e45f98b2b3391320d96e8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ad07e0d1bd3e45f98b2b3391320d96e8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
