import { template as template_dd72731e421c4cb98b3b872982eb388a } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_dd72731e421c4cb98b3b872982eb388a(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
